/* eslint-disable react/jsx-props-no-spreading */
import {
  isNotFoundError,
  isNotLoggedInError,
  isPermissionError,
  isTimeoutError,
  getPreferredErrorMessage,
  isTRPCClientError,
  isNetworkError,
} from '@newfront-insurance/data-layer-client';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorMessage } from './components/error-message';
import type { ErrorBoundaryContainer, ErrorBoundaryMessages, ErrorCallback, ErrorMessageProps } from './types';

interface Props {
  children: React.ReactNode;
  messages: ErrorBoundaryMessages;
  onError?: ErrorCallback;
  container?: ErrorBoundaryContainer;
}

export function ErrorBoundaryWithMessages({ children, onError, messages, container }: Props): JSX.Element {
  const Container = container ?? ErrorMessage;

  return (
    <ErrorBoundary
      onError={onError}
      fallbackRender={({ error }) => {
        const message = getPreferredErrorMessage(error);

        // If it's a TRPCClientError we want to use the cause of the error instead. Otherwise
        // the error message won't be useful.
        const errorCause = isTRPCClientError(error) && error.cause ? error.cause : error;
        let errorProps: ErrorMessageProps;

        if (isPermissionError(errorCause)) {
          errorProps = messages.permissionError(errorCause);
        } else if (isTimeoutError(errorCause)) {
          errorProps = messages.timeoutError(errorCause);
        } else if (isNetworkError(errorCause)) {
          errorProps = messages.networkError(errorCause);
        } else if (isNotFoundError(errorCause)) {
          errorProps = messages.notFoundError(errorCause);
        } else if (isNotLoggedInError(errorCause)) {
          errorProps = messages.notLoggedInError(errorCause);
        }

        // Capture any other HTTP error, like a 400 or 50x error. This will show the NestJS error message
        // or fetcher error. Any other type error will just show the error.message.
        errorProps = {
          ...messages.genericError(errorCause),
          rawErrorMessage: message,
        };

        return <Container {...errorProps} />;
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
