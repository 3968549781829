import type { ErrorBoundaryContainer } from '@newfront-insurance/admin-ui/layout';
import { IconBug, IconNetworkLost, IconNotAllowed, IconNotFound, Text } from '@newfront-insurance/core-ui';
import { Button } from '@newfront-insurance/core-ui-analytics';
import { useMemo } from 'react';

import { createErrorBoundary } from './create';

import { getConfig } from '@/config';

const { LINKS } = getConfig();

export function PageErrorBoundary({
  children,
  container,
}: {
  children: React.ReactNode;
  container?: ErrorBoundaryContainer;
}): JSX.Element {
  const ErrorBoundaryWrapper = useMemo(() => {
    return createErrorBoundary({
      container,
      onError: (error) => console.error(error),
      messages: {
        genericError: () => ({
          title: 'There was a problem loading this page',
          icon: <IconBug />,
          subtitle: (
            <Text textAlign="center">
              Try reloading the page to fix the issue. If you’re still experiencing this issue please contact your
              servicing team.
            </Text>
          ),
        }),
        notFoundError: () => ({
          title: 'The page you’re looking for doesn’t exist',
          icon: <IconNotFound />,
          action: <HomeButton />,
          subtitle: (
            <Text textAlign="center">
              Check the URL. If you think this is a mistake please contact your servicing team.
            </Text>
          ),
        }),
        permissionError: () => ({
          title: 'You don’t have permission to view this page',
          icon: <IconNotAllowed />,
          subtitle: <Text textAlign="center">If you think this is a mistake you can contact your servicing team.</Text>,
        }),
        networkError: () => ({
          title: 'There was a network connection issue that prevented this page from loading',
          icon: <IconNetworkLost />,
          action: <ReloadButton />,
          subtitle: <Text textAlign="center">Please check your internet connection and try again.</Text>,
        }),
        timeoutError: () => ({
          title: 'The page took too long to load',
          icon: <IconNetworkLost />,
          action: <ReloadButton />,
          subtitle: (
            <Text textAlign="center">
              Please check your internet connection and try again. If the issue persists please contact your servicing
              team.
            </Text>
          ),
        }),
        notLoggedInError: () => ({
          title: 'You need to be logged in to view this page',
          icon: <IconNotAllowed />,
          subtitle: <Text>If you think this is a mistake you can contact your servicing team.</Text>,
        }),
      },
    });
  }, [container]);
  return <ErrorBoundaryWrapper>{children}</ErrorBoundaryWrapper>;
}

export const ModalErrorBoundary = createErrorBoundary({
  onError: (error) => console.error(error),
  messages: {
    genericError: () => ({
      title: 'There was a problem loading this content',
      icon: <IconBug />,
      subtitle: (
        <Text textAlign="center">
          Try reloading the page to fix the issue. If you’re still experiencing this issue please contact your servicing
          team.
        </Text>
      ),
    }),
    notFoundError: () => ({
      title: 'The content you’re looking for doesn’t exist',
      icon: <IconNotFound />,
      subtitle: <Text textAlign="center">If you think this is a mistake please contact your servicing team.</Text>,
    }),
    permissionError: () => ({
      title: 'You don’t have permission to view this content',
      icon: <IconNotAllowed />,
      subtitle: <Text textAlign="center">If you think this is a mistake you can contact your servicing team.</Text>,
    }),
    networkError: () => ({
      title: 'There was a network connection issue that prevented this content from loading',
      icon: <IconNetworkLost />,
      action: <ReloadButton />,
      subtitle: <Text textAlign="center">Please check your internet connection and try again.</Text>,
    }),
    timeoutError: () => ({
      title: 'The content took too long to load',
      icon: <IconNetworkLost />,
      action: <ReloadButton />,
      subtitle: (
        <Text textAlign="center">
          Please check your internet connection and try again. If the issue persists please contact your servicing team.
        </Text>
      ),
    }),
    notLoggedInError: () => ({
      title: 'You need to be logged in to view this content',
      icon: <IconNotAllowed />,
      subtitle: <Text>If you think this is a mistake you can contact your servicing team.</Text>,
    }),
  },
});

function ReloadButton(): JSX.Element {
  return (
    <Button
      size="secondary"
      onClick={() => window.location.reload()}
      trackOnClick={{ eventName: 'Reload button clicked' }}
    >
      Try again
    </Button>
  );
}

function HomeButton(): JSX.Element {
  return (
    <Button size="secondary" href={LINKS.CLIENT_DASH_APP} trackOnClick={{ eventName: 'Return home button clicked' }}>
      Return home
    </Button>
  );
}
